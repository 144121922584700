window.addEventListener("DOMContentLoaded", function() {
    const GATAG_ID = "G-389Z3S4RHE";
    const cc = window.cookieconsent.initialise({
        palette: {
            popup: {
                background: "#000"
            },
            button: {
                background: "#f1d600"
            }
        },
        theme: "classic",
        content: {
            message: "Our website uses cookies for analytics to understand visitor numbers. This helps us improve our site and ensure you get the best experience. We don't track individual behavior or personal details. Your privacy matters to us.",
            dismiss: "Got it!",
            link: "Learn more",
            href: "http://cookiesandyou.com"
        },
        onInitialise: function(status) {
            console.log('onInitialise', this.hasConsented() );
            if (this.hasConsented()) {
                // User has accepted cookies, initialize Google Analytics
                loadGoogleAnalytics();
            }
        },
        onStatusChange: function(status) {
            console.log('onsStatusChange', this.hasConsented() );
            if (this.hasConsented()) {
                // User has accepted cookies, initialize Google Analytics
                loadGoogleAnalytics();
            }
        },
        law: {
            regionalLaw: false,
        },
        location: true,
    });

    function loadGoogleAnalytics() {
        console.log('loadingGoogleAnalytics');
        // Adding the gtag.js script dynamically
        var script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=' + GATAG_ID;
        document.head.appendChild(script);

        // Initializing the data layer and the gtag function
        window.dataLayer = window.dataLayer || [];
        function gtag() {
            dataLayer.push(arguments);
        }
        window.gtag = gtag;

        // Setting the current time and configuring gtag with your tracking ID
        gtag('js', new Date());
        gtag('config', GATAG_ID);
    }

});